import { extendTheme } from '@chakra-ui/react'
import colors from './colors'
import global from './global'
import sizes from './sizes'
import fonts from './fonts'
import heading from './components/heading'
import card from './components/card'
import button from './components/button'
import layerStyles from './layerStyles'

const theme = {
  config: { initialColorMode: 'dark', useSystemColorMode: false, defaultColorMode: 'dark' },
  styles: {
    global,
  },
  layerStyles,
  colors,
  sizes,
  fonts,
  components: {
    Heading: heading,
    Card: card,
    Button: button,
  },
}

export default extendTheme(theme)
