import { defineStyle, defineStyleConfig } from '@chakra-ui/react'

const md = {
  fontSize: 24,
  lineHeight: '32px',
  fontWeight: 700,
  borderRadius: '2xl',
}

const button = defineStyleConfig({
  baseStyle: {
    p: 1,
    fontSize: 18,
    transition: 'all 0.2s',
  },
  variants: {
    solidBorder: defineStyle(({ colorScheme: c }) => {
      let bgColor = `${c}.400`
      let bgColorHover = `${c}.300`
      let bgColorActive = `${c}.600`
      let borderColor = `${c}.600`
      let color = 'black'

      return {
        bgColor,
        border: '4px solid',
        borderColor,
        color,
        _hover: {
          bgColor: bgColorHover,

          _disabled: {
            bgColor: 'gray.200',
          },
        },
        _active: {
          bgColor: bgColorActive,
        },
        _disabled: {
          bgColor: 'gray.200',
          borderColor: 'blackAlpha.400',
          opacity: 1,
          color: 'black',
        },
      }
    }),
  },
  sizes: {
    md: {
      ...md,
      py: 4,
      height: 16,
    },
  },
})

export default button
