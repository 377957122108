const colors = {
  black: {
    300: '#272727',
    400: '#1B1B1B',
  },
  gray: {
    200: '#4F4F4F',
  },
  yellow: {
    300: '#F6D988',
    400: '#F3CC62',
    500: '#EFBA2C',
    600: '#AF9137',
  },
}

export default colors
