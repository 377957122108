const card = {
  parts: ['container'],
  variants: {
    dark: {
      container: {
        borderRadius: 24,
        bgColor: 'black.300',
        color: 'white',
        transition: 'all .3s',
      },
    },
  },
}

export default card
